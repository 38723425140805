import { Component } from "react";
import { withRouter } from "react-router";

// Import FontAwesome and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

// Import popup alerts
import Swal from "sweetalert2";

// Import utils
import { importProjectJsonFile } from "../../../Utils/utils";

// Import CSS
import "./ImportProject.css";

class ImportProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isimporting: false,
            loadedProject: ""
        }
    }


    // Function to cancel import
    cancelImport = () => {
        this.setState({ isimporting: false })
        document.getElementById("selectFiles_1").value = ""
        document.getElementById("label-upload").innerHTML = "Importer un projet"
    }

    render() {
        const loadImportedProj = () => {
            if (this.state.loadedProject && this.state.loadedProject.type === "application/json") {
                // Call function to load the project
                importProjectJsonFile(this.state.loadedProject)

            } else {
                Swal.fire({
                    title: 'Impossible de lire ce type de fichier !',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: 'OK',
                    icon: 'warning',
                })
            }
        }

        return (
            <div className="import_project">

                <label className="adno-upload-file" id="label-upload" htmlFor="selectFiles_1"> <FontAwesomeIcon icon={faUpload} /> Importer un projet</label>

                <input accept="application/json" type="file" id="selectFiles_1" onChange={(e) => {
                    this.setState({ isimporting: true, loadedProject: e.target.files[0] })
                    document.getElementById("label-upload").innerHTML = "Fichier selectionné : " + e.target.files[0].name
                }} />

                {
                    this.state.isimporting &&
                    <div className="import-btns">
                        <button className="import-btn import-reset" disabled={!this.state.isimporting} onClick={() => this.cancelImport()}>Annuler l'importation</button>
                        <button id="import_1" className="import-btn import-confirm" disabled={!this.state.isimporting} onClick={() => loadImportedProj()}>Importer mon projet</button>
                    </div>
                }


            </div>
        )
    }
}

export default withRouter(ImportProject)